/**
 *
 * This page container contains the main tag and transition logic. It should
 * wrap all pages in their respective template files. If used in DefaultLayout,
 * it cannot access Transition State.
 *
 */

import React from "react";
import classNames from "classnames";
import { AnimatePresence, m } from "framer-motion";

const PageContainer = ({ children, className, path }) => {
  return (
    <m.main
      key={path}
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 100 }}
      transition={{
        type: "tween",
        ease: "easeInOut",
        duration: 0.5,
      }}
      onAnimationComplete={definition => {
        if (definition === "enter") {
          if (typeof window !== "undefined") {
            const dataLayer = window.dataLayer || [];
            dataLayer.push({ event: "routeChange" });
          }
        }
      }}
      className={classNames(
        className,
        "texture-pattern relative z-0 flex min-h-screen w-screen flex-grow flex-col bg-viridian"
      )}
    >
      <AnimatePresence initial>{children}</AnimatePresence>
    </m.main>
  );
};

PageContainer.defaultProps = {
  className: "",
};

export default PageContainer;
